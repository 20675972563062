import React from 'react'

import { Container } from '../../Grid'
import { Market } from './styled'
import { groupBy } from '../../../utils'

import Section from './Section'
import State from './State'

const Locations = ({ rel = '', markets = [], onClose }) => {
  const groupByState = groupBy(markets, 'state.name')
  const states = Object.keys(groupByState).sort()

  return <Container>
    <Section title='Locations' subTitle='Explore' columns>
      {states.map((state, s) =>
        <State key={s} name={state}>
          {groupByState[state].map((market, m) =>
            <Market
              key={m}
              rel={rel}
              onClick={onClose}
              href={market.marketPage && market.marketPage.slug}
            >
              {market.title}
            </Market>
          )}
        </State>
      )}
    </Section>
  </Container>
}

export default Locations
