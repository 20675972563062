import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/Layout'
import Locations from '../components/Nav/Locations/Locations'

const Container = styled.div`
  padding-top: 30px;
`

const Page = ({ data }) => {
  const { menu, markets, ...account } = data.root.account
  return <Layout
    account={account}
    page={{
      title: 'Search',
      description: 'Find an apartment'
    }}
    menu={menu}
    markets={markets.items}
  >
    <Container>
      <Locations show={true} markets={markets.items} />
    </Container>
  </Layout>
}

export const query = graphql`
  query getSearchPageData($account: ID) {
    root {
     account: getAccountById(id: $account) {
        name
        title
        googleSiteVerification
        menu {
          title
          href
          subMenu {
            title
            subTitle
            href
          }
        }

        markets(sort: [["market", "1"]]) {
          count
          items {
            title: market
            state {
              name
            }
            marketPage {
              slug
            }
            apartments(filter: { status:published } sort: [["name", "1"]]) {
              items {
                name
                marketingWebsiteUrl
              }
            }
          }
        }
      }
    }
  }
`

export default Page
