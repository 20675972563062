import styled from 'styled-components'

import Link from '../../Link'

export const Location = styled(Link)`
  font-family: Open Sans, sans-serif;
  font-weight: 300;
  display: inline-block;
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 5px 20px 5px 0;
  font-weight: 300;

  @media (min-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: 992px) {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
`

export const Market = styled(Location)`
  padding-top: 5px;
`

export const Wrapper = styled.div`
  width: 100%;
  position: fixed;
  left: 0;
  background-color: ${ props => props.theme.nav.locations.backgroundColor };
  z-index: 1040;

  transition: top .15s ease;
  height: calc(100% - 64px);
  top: ${ props => props.show ? '64px' : '100%' };
`

export const ScrollContainer = styled.div`
  padding: 30px 0;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`
