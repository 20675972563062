import React from 'react'
import styled from 'styled-components'

import { Row, Col } from '../../Grid'
import Link from '../../Link'

const Section = styled(Row)`
  margin-bottom: 20px;
`

const Aside = styled(Col)`
  @media (min-width: 768px) {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  @media (min-width: 992px) {
    flex: 0 0 20%;
    max-width: 20%;
  }
`

const Body = styled(Col)`
  ${ props => props.columns ? 'columns: 1;' : '' }

  @media (min-width: 768px) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    ${ props => props.columns ? 'columns: 2;' : '' }
  }

  @media (min-width: 992px) {
    flex: 0 0 80%;
    max-width: 80%;
    ${ props => props.columns ? 'columns: 3;' : '' }
  }
`

const SubTitle = styled(Col)`
  color: ${ props => props.theme.nav.locations.headerColor };
  font-size: 1.1em;
  font-family: ${ props => props.theme.fonts.header };
`

const Title = styled(Link)`
  color: ${ props => props.theme.nav.locations.bodyColor };
  font-size: 1.5em;
  font-weight: 400;
`

export default ({ href, title, subTitle, columns, children }) =>
  <Section>
    {subTitle ? <SubTitle>{subTitle}</SubTitle> : null}
    <Aside>
      <Title href={href}>{title}</Title>
    </Aside>
    <Body columns={columns}>{children}</Body>
  </Section>
