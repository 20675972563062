import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1.25rem;
    break-inside: avoid;
`

const Title = styled.span`
  font-size: 1.1em;
  font-weight: 700;
  color: ${ props => props.theme.nav.locations.bodyColor };
`

export default ({ name, children }) =>
  <Container>
    <Title>{name}</Title>
    {children}
  </Container>
